import { WindowLocation } from "@reach/router";
import classNames from "classnames";
import { graphql, navigate } from "gatsby";
import flatten from "lodash.flatten";
import sortBy from "lodash.sortby";
import uniqBy from "lodash.uniqby";
import React, { useCallback, useEffect, useState } from "react";
import AnchorButton from "../components/AnchorButton/AnchorButton";
import BlockContent from "../components/BlockContent/BlockContent";
import Breadcrumbs, { Breadcrumb } from "../components/Breadcrumbs/Breadcrumbs";
import Button from "../components/Button/StandardButton/Button";
import Card from "../components/Card/Card";
import { Col, Row, Wrapper } from "../components/Grid";
import IsDesktop from "../components/IsDesktop/IsDesktop";
import IsMobile from "../components/IsMobile/IsMobile";
import MediaCarousel from "../components/MediaCarousel/MediaCarousel";
import BuyModal from "../components/Modals/BuyModal/BuyModal";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import WithSpacing from "../components/Spacing/Spacing";
import Text from "../components/Text/Text";
import GetText, { useString } from "../i18n/GetText";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { SanityProduct } from "../model/buy";
import {
  CommonContext,
  SanityCallToAction,
  SanityCountry,
  SanityEcosystemMember,
  SanityPage,
  SanityRawContent
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import { fetchCachedBrowserInfo } from "../utils/browser-info";
import { useConsent } from "../utils/compliance";
import styles from "./ProductPage.module.scss";
import { WhereToBuyQuery } from "./WhereToBuyPage";

export const pageQuery = graphql`
  query ProductPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    resellerListingPage: sanityBuyPage(language: { eq: null }) {
      _id
      _type
      title
      _rawChannelPartnersDescription(resolveReferences: { maxDepth: 5 })
    }
    productListingPage: sanityProductListingPage(language: { eq: null }) {
      _id
      _type
      title
    }
    buyConfig: sanityBuyConfig {
      learnMore {
        title
        internalLink {
          reference {
            ...InternalLinkTarget
          }
          fragment
          query
        }
      }
      _rawContent(resolveReferences: { maxDepth: 5 })
    }
    page: sanityProduct(_id: { eq: $_id }) {
      _id
      _type
      title
      subtitle
      description {
        _rawContent(resolveReferences: { maxDepth: 5 })
      }
      _rawContent(resolveReferences: { maxDepth: 5 })
      image {
        ...SanitySimpleImage
        ...SanityHotspotCrop
      }
      additionalImages {
        ...SanitySimpleImage
        ...SanityHotspotCrop
      }
      slug {
        current
      }
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    resellers: allSanityEcosystemMember(
      # Match everything. Glob or regex give duplicate results.
      filter: { products: { nin: "__" } }
      sort: { order: ASC, fields: name }
    ) {
      nodes {
        _id
        name
        resellerUrl
        resellerProducts {
          product {
            _id
            _type
            title
            slug {
              current
            }
          }
          productUrl
        }
        channelPartner
        countries {
          code
          name
        }
        logo {
          ...SanityImage
        }
      }
    }
  }
`;

interface ProductPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityProduct;
    buyConfig: {
      learnMore: SanityCallToAction;
      _rawContent: SanityRawContent;
    };
    productListingPage: SanityPage;
    resellerListingPage: SanityPage & {
      _rawChannelPartnersDescription: SanityRawContent;
    };
    resellers: { nodes: SanityEcosystemMember[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

const ProductPage = ({
  pageContext,
  data: {
    menus,
    page,
    productListingPage,
    resellerListingPage,
    buyConfig,
    resellers: { nodes: resellers }
  },
  location: windowLocation
}: ProductPageProps) => {
  const { title, subtitle, description, _rawContent: content } = page;
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState(false);
  const { location, setLocation, resetLocation } = useLocation();
  const productSlug = page.slug.current;
  const { channelPartners, locationOptions } = useBuyProductParams(
    resellers,
    productSlug,
    location
  );
  const whereToBuyUrl = useCallback(
    (params: Partial<WhereToBuyQuery>) => {
      const queryString = Object.entries({ ...params })
        .filter(([_, v]) => Boolean(v))
        .map(([k, v]) => `${k}=${encodeURIComponent(v!)}`)
        .join("&");
      return `${urlForDocument(resellerListingPage)}${
        queryString ? `?${queryString}` : ""
      }`;
    },
    [resellerListingPage]
  );
  const handleBuy = useCallback(() => {
    if (channelPartners.length > 0) {
      setIsBuyDialogOpen(true);
    } else {
      navigate(whereToBuyUrl({ product: productSlug, location }));
    }
  }, [channelPartners.length, location, productSlug, whereToBuyUrl]);
  const handleBuyDialogClose = useCallback(() => {
    resetLocation();
    setIsBuyDialogOpen(false);
  }, [resetLocation]);
  const breadcrumbs: Breadcrumb[] = [
    {
      title: productListingPage.title,
      href: urlForDocument(productListingPage)
    },
    {
      title: page.title,
      href: urlForDocument(page)
    }
  ];
  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.BUY}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      location={windowLocation}
      strings={pageContext.strings}
    >
      <BuyModal
        product={page}
        isOpen={isBuyDialogOpen}
        onClose={handleBuyDialogClose}
        resellers={channelPartners}
        setLocation={setLocation}
        location={location}
        locationOptions={locationOptions as any}
        otherResellersUrl={whereToBuyUrl({ product: productSlug, location })}
        channelPartnerDescription={
          resellerListingPage._rawChannelPartnersDescription
        }
      />
      <Wrapper className={styles.root}>
        <Row justify="center">
          <Col lg={10} md={12} sm={12}>
            <Row justify="between" style={{ marginBottom: "10px" }}>
              <Col>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </Col>
              <Col>
                <SocialLinks title={title} />
              </Col>
            </Row>
            <Card type="main">
              <IsMobile>
                <Row>
                  <Col md={12}>
                    <Text variant="h1">{title}</Text>
                  </Col>
                </Row>
              </IsMobile>
              <Row className={styles.carouselInfoRow}>
                <Col md={6} sm={6} xs={12} className={styles.carouselCol}>
                  <MediaCarousel
                    media={[
                      ...(page.image ? [page.image] : []),
                      ...page.additionalImages
                    ]}
                  />
                </Col>
                <Col md={6} sm={6} xs={12} className={styles.info}>
                  <WithSpacing>
                    <div>
                      <IsDesktop>
                        <Text variant="h1">{title}</Text>
                      </IsDesktop>
                      <Text variant="h3">{subtitle}</Text>
                      <BlockContent content={description?._rawContent} />
                    </div>
                  </WithSpacing>
                  <div className={styles.buttons}>
                    <AnchorButton
                      to={urlForDocument(
                        buyConfig.learnMore.internalLink!.reference
                      )}
                    >
                      {buyConfig.learnMore.title}
                    </AnchorButton>
                    <Button
                      className={styles.buyButton}
                      onClick={handleBuy}
                      primary
                    >
                      <GetText id="buy" fallback="Buy" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {content && Array.isArray(content) && content.length > 0 && (
          <Row justify="center">
            <Col lg={10} md={12} sm={12}>
              <Card type="main">
                <BlockContent
                  content={[...content, ...buyConfig._rawContent]}
                  cap="2/3"
                />
                <Button
                  className={classNames(styles.callToAction, styles.buyButton)}
                  onClick={handleBuy}
                  primary
                >
                  <GetText id="buy" fallback="Buy" />
                </Button>
              </Card>
            </Col>
          </Row>
        )}
      </Wrapper>
    </PageLayout>
  );
};

const useLocation = (): {
  location: string | undefined;
  setLocation: (loc: string | undefined) => void;
  resetLocation: () => void;
} => {
  const consent = useConsent();
  const [initialised, setInitialised] = useState<boolean>(false);
  const locationState = useState<string | undefined>(undefined);
  const [location, setLocation] = locationState;

  const resetLocation = useCallback(async () => {
    const { country } = await fetchCachedBrowserInfo(consent);
    setLocation(country);
    return country;
  }, [consent, setLocation]);

  useEffect(() => {
    if (!initialised) {
      resetLocation();
    }
    setInitialised(true);
  }, [consent, initialised, location, resetLocation, setLocation]);
  return { location, setLocation, resetLocation };
};

const useBuyProductParams = (
  unfilteredResellers: SanityEcosystemMember[],
  productSlug: string,
  location: string | undefined
) => {
  const allLocations = useString("all-locations", "All locations");
  const channelPartners = unfilteredResellers.filter(
    r =>
      r.channelPartner &&
      r.resellerProducts.find(p => p.product.slug.current === productSlug)
  );
  const locations: SanityCountry[] = sortBy(
    uniqBy(flatten(unfilteredResellers.map(r => r.countries)), c => c.code),
    c => c.name
  );
  const locationOptions = [
    { value: undefined, label: allLocations },
    ...locations.map(c => ({ value: c.code, label: c.name }))
  ];
  const isLocationAvailable = locations.find(x => x.code === location);
  const channelPartnersByLocation =
    !location || !isLocationAvailable
      ? channelPartners
      : channelPartners.filter(r => r.countries.find(c => c.code === location));
  return { channelPartners: channelPartnersByLocation, locationOptions };
};

export default ProductPage;
