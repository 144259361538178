import React, { useCallback } from "react";
import GetText, { useString } from "../../../i18n/GetText";
import { SanityProduct } from "../../../model/buy";
import { SanityEcosystemMember, SanityRawContent } from "../../../model/common";
import { imageUrlBuilder } from "../../../utils/images";
import BuyFilter from "../../BuyFilter/BuyFilter";
import Card from "../../Card/Card";
import { Col, Row } from "../../Grid";
import LinkOverlay from "../../LinkOverlay/LinkOverlay";
import LinkOverlayContainer from "../../LinkOverlayContainer/LinkOverlayContainer";
import Modal from "../../Modal/Modal";
import ModalBody from "../../Modal/ModalBody/ModalBody";
import WithSpacing from "../../Spacing/Spacing";
import Text from "../../Text/Text";
import styles from "./BuyModal.module.scss";
import Button from "../../Button/StandardButton/Button";
import { Link, navigate } from "gatsby";
import BlockContent from "../../BlockContent/BlockContent";
import InfoToolTip from "../../InfoTooltip/InfoTooltip";

interface BuyModalProps {
  isOpen: boolean;
  onClose: () => void;
  resellers: SanityEcosystemMember[];
  setLocation: (loc: string) => void;
  location: string | undefined;
  product: SanityProduct;
  locationOptions: Array<{ value: string; label: string }>;
  otherResellersUrl: string;
  channelPartnerDescription?: SanityRawContent;
}

const BuyModal = ({
  isOpen,
  onClose,
  resellers,
  setLocation,
  location,
  locationOptions,
  product,
  otherResellersUrl,
  channelPartnerDescription = undefined
}: BuyModalProps) => {
  const buy = useString("buy", "Buy");
  const channelPartnersTooltipLabel = useString(
    "channel-partners-tooltip",
    "Channel partners tooltip"
  );

  const handleViewOtherResellers = useCallback(() => {
    navigate(otherResellersUrl);
  }, [otherResellersUrl]);
  return (
    <Modal
      id="buyModal"
      isOpen={isOpen}
      title={`${buy} ${product.title}`}
      onClose={onClose}
      dismissible
    >
      <div className={styles.root}>
        <ModalBody>
          <WithSpacing>
            <div>
              <Text variant="default">
                <GetText
                  id="buy-modal-intro"
                  fallback="Our products are sold through channel partners and
                resellers."
                />
              </Text>
            </div>
          </WithSpacing>
          <Row>
            <Col md={6} sm={6} xs={12}>
              <BuyFilter
                value={location || null}
                placeholderId="location"
                placeholderFallback="Location"
                onChange={value => setLocation(value)}
                options={locationOptions}
                formatOptionLabel={({ label }: any) => (
                  <Text as="span" variant="default">
                    {label}
                  </Text>
                )}
              />
            </Col>
          </Row>

          {resellers.length > 0 ? (
            <>
              <Row className={styles.textWithTooltip}>
                <Text variant="h3">
                  <GetText id="channel-partners" fallback="Channel partners" />
                </Text>
                {channelPartnerDescription && (
                  <InfoToolTip ariaLabel={channelPartnersTooltipLabel}>
                    <Text variant="default">
                      <BlockContent content={channelPartnerDescription} />
                    </Text>
                  </InfoToolTip>
                )}
              </Row>
              <Row className={styles.resellerGrid}>
                {resellers.map(r => (
                  <ResellerCard
                    key={r._id}
                    reseller={r}
                    productSlug={product.slug.current}
                  />
                ))}
              </Row>
              <Text variant="default" className={styles.resultText}>
                <Link to={otherResellersUrl}>
                  <GetText
                    id="buy-modal-view-resellers"
                    fallback="View all micro:bit resellers"
                  />
                </Link>
              </Text>
            </>
          ) : (
            <>
              <Row className={styles.textWithTooltip}>
                <Text variant="defaultBold">
                  <GetText
                    id="buy-modal-no-result"
                    fallback="No channel partners for this location."
                  />
                </Text>
                {channelPartnerDescription && (
                  <InfoToolTip ariaLabel={channelPartnersTooltipLabel}>
                    <Text variant="default">
                      <BlockContent content={channelPartnerDescription} />
                    </Text>
                  </InfoToolTip>
                )}
              </Row>
              <Button
                onClick={handleViewOtherResellers}
                className={styles.viewResellersBtn}
                primary={resellers.length === 0}
              >
                <GetText
                  id="buy-modal-view-resellers"
                  fallback="View all micro:bit resellers"
                />
              </Button>
            </>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

interface ResellerCardProps {
  reseller: SanityEcosystemMember;
  productSlug: string;
}

const ResellerCard = ({ reseller, productSlug }: ResellerCardProps) => {
  const url = reseller.resellerProducts.find(
    p => p.product.slug.current === productSlug
  )?.productUrl!;

  return (
    <Col md={4} sm={4} xs={12}>
      <LinkOverlayContainer>
        <Card className={styles.card}>
          <LinkOverlay
            to={url}
            rel={
              process.env.GATSBY_STAGE === "PRODUCTION"
                ? "noopener"
                : "noreferer"
            }
          >
            <img
              width={180}
              height={60}
              loading="lazy"
              src={
                imageUrlBuilder
                  .image(reseller.logo)
                  .fit("fillmax")
                  .bg("fff")
                  .width(360)
                  .height(100)
                  .ignoreImageParams()
                  .url()!
              }
              alt={reseller.name}
            />
          </LinkOverlay>
        </Card>
      </LinkOverlayContainer>
    </Col>
  );
};

export default BuyModal;
