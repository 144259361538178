import React, { useCallback, useEffect } from "react";
import { ReactNode, useState } from "react";
import InfoIcon from "../../icons/InfoIcon";
import PlainButton from "../Button/PlainButton/PlainButton";
import styles from "./InfoTooltip.module.scss";
import { useModalManager } from "../../utils/useModalManager";

export interface InfoToolTipProps {
  ariaLabel: string;
  children: ReactNode;
}

const InfoToolTip = ({ children, ariaLabel }: InfoToolTipProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { removeModal, addModal, isTopModal } = useModalManager();
  const tooltipId = "info-tooltip";
  const handleOpen = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      addModal(tooltipId);
    }
  }, [addModal, isOpen]);
  const handleClose = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      removeModal(tooltipId);
    }
  }, [isOpen, removeModal]);
  const keyListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && isTopModal(tooltipId)) {
        handleClose();
      }
    },
    [handleClose, isTopModal]
  );
  const addEventListeners = useCallback(() => {
    window.addEventListener("keydown", keyListener);
  }, [keyListener]);

  const removeEventListeners = useCallback(() => {
    window.removeEventListener("keydown", keyListener);
  }, [keyListener]);

  useEffect(() => {
    if (isOpen) {
      addEventListeners();
    } else {
      removeEventListeners();
    }
    return removeEventListeners;
  }, [addEventListeners, isOpen, removeEventListeners]);

  return (
    <div className={styles.root}>
      <PlainButton
        className={styles.button}
        onClick={handleOpen}
        onFocus={handleOpen}
        onBlur={handleClose}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        aria-describedby={tooltipId}
        aria-label={ariaLabel}
      >
        <InfoIcon className={styles.icon} />
      </PlainButton>
      {isOpen && (
        <div role="tooltip" id={tooltipId} className={styles.tooltip}>
          <div className={styles.tooltipArrow} />
          <div className={styles.tooltipBox}>{children}</div>
        </div>
      )}
    </div>
  );
};
export default InfoToolTip;
