import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { BsFillPlayBtnFill } from "react-icons/bs";
import Swiper from "swiper";
import { SanityImage, SanityYoutubeVideo } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import { useIsRtl } from "../../utils/LayoutContext";
import PlainButton from "../Button/PlainButton/PlainButton";
import CarouselButton from "../CarouselButton/CarouselButton";
import SwiperCarousel from "../SwiperCarousel/SwiperCarousel";
import YoutubeVideo from "../YoutubeVideoBlock/YoutubeVideoBlock";
import styles from "./MediaCarousel.module.scss";

interface MediaCarouselProps {
  className?: string;
  media: (SanityImage | SanityYoutubeVideo)[];
}

const MediaCarousel = ({ media, className }: MediaCarouselProps) => {
  const [swiper, setSwiper] = useState<Swiper>();
  const isRtl = useIsRtl();
  const getKey = useCallback(
    (media: SanityImage | SanityYoutubeVideo) =>
      media._type === "simpleImage"
        ? (media as SanityImage).asset._id
        : (media as SanityYoutubeVideo).youtubeId,
    []
  );
  const slides = media.map(media => (
    <div key={getKey(media)} className={styles.slide}>
      {media._type === "simpleImage" ? (
        <img
          src={
            imageUrlBuilder
              .image(media)
              .width(800)
              .height(600)
              .url()!
          }
          alt={media.alt ?? ""}
          width={800}
          height={600}
        />
      ) : (
        <div className={styles.videoContainer}>
          <YoutubeVideo
            className={styles.video}
            node={media as SanityYoutubeVideo}
          />
        </div>
      )}
    </div>
  ));

  const slideToMedia = useCallback(
    (index: number) => {
      // When using loop mode, the active index is offset by the number of looped slides.
      // This function is a workaround to ensure the clicked thumbnail moves to the
      // correct slide.
      const firstSlide = swiper?.el.querySelector("li");
      const slideOffset =
        firstSlide?.getAttribute("data-swiper-slide-index") ?? "0";
      swiper?.slideTo(
        (media.length + index - parseInt(slideOffset)) % media.length
      );
    },
    [swiper, media.length]
  );

  const thumbnails = media.map((media, index) => (
    <PlainButton key={getKey(media)} onClick={() => slideToMedia(index)}>
      {media._type === "simpleImage" ? (
        <img
          src={
            imageUrlBuilder
              .image(media)
              .width(72)
              .height(54)
              .url()!
          }
          alt={media.alt ?? ""}
          width={72}
          height={54}
        />
      ) : (
        <div className={styles.videoThumbnail}>
          <img
            src={`https://i.ytimg.com/vi/${
              (media as SanityYoutubeVideo).youtubeId
            }/mqdefault.jpg`}
            alt={media.alt ?? ""}
            width="100%"
          />
          {/* Needed to fill the icon cutout with white. */}
          <div className={styles.videoIconFill}></div>
          <BsFillPlayBtnFill
            className={styles.videoIcon}
            color="rgb(255,0,0)"
            size="30"
          />
        </div>
      )}
    </PlainButton>
  ));

  useEffect(() => {
    const listener = (e: Swiper) => {
      e.slides.forEach(slide => {
        const iframe = slide.querySelector("iframe");
        if (iframe) {
          iframe.contentWindow?.postMessage(
            JSON.stringify({ event: "command", func: "pauseVideo" }),
            "*"
          );
        }
      });
    };
    if (swiper) {
      swiper.on("slideChange", listener);
    }
    return () => {
      if (swiper) {
        swiper?.off("slideChange", listener);
      }
    };
  }, [swiper]);

  return (
    <div className={styles.root}>
      <div className={classnames(styles.image, className)}>
        <CarouselButton
          direction={isRtl ? "right" : "left"}
          size="small"
          onClick={() => swiper?.slidePrev()}
          shadow={false}
        />
        <div className={styles.carousel}>
          <SwiperCarousel
            slidesPerView={1}
            itemTypeMessage="images"
            onSwiper={setSwiper}
            carouselItems={slides}
            loop
          />
        </div>
        <CarouselButton
          direction={isRtl ? "left" : "right"}
          size="small"
          onClick={() => swiper?.slideNext()}
          shadow={false}
        />
      </div>
      <div className={styles.thumbnails}>{thumbnails}</div>
    </div>
  );
};
export default MediaCarousel;
