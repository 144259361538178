import React from "react";

interface InfoIconProps {
  className?: string;
  fill?: string;
  height?: number;
  width?: number;
}

const InfoIcon = ({
  className,
  fill,
  height = 20,
  width = 20
}: InfoIconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    {/* SVG taken from ri/RiInformationLine icon in 
    https://react-icons.github.io/react-icons/icons/ri/  */}
    <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 7H13V9H11V7ZM11 11H13V17H11V11Z" />
  </svg>
);

export default InfoIcon;
